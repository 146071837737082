import { ApolloProvider } from "@apollo/client";
// import CssBaseline from "@mui/material/CssBaseline";
import { PaletteMode } from "@mui/material";
import ProductConfiguratorProvider from "@RHCommerceDev/custom-providers/ProductConfiguratorProvider";
import { RHNotificationProvider } from "component-rh-notification-modal";
import express from "express";
import type { ClientType } from "graphql-client/client-type";
import React, { Component, createContext } from "react";
import { KeycloakProviderAdapter } from "utils-keycloak/KeyCloak";
import { getIsReactMicrosite } from "utils/getIsReactRoute";
import memoize from "utils/memoize";
import AvailableCountryProvider from "./AvailableCountryProvider";
import CountrySiteProvider from "./CountrySiteProvider";
import ErrorProvider from "./ErrorProvider";
import SessionProvider from "./SessionProviderV2";
import UserPreferencesProvider from "./UserPreferencesProvider";

type PropType = { [key: string]: any };

class ErrorBoundary extends Component {
  state: { hasError: boolean };
  constructor(props: PropType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // TODO: Log this to service
    console.log("React Error", error, errorInfo);
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

const EB = memoize(ErrorBoundary) as React.ComponentType;

export const UseRequestProvider: any = createContext({});
export const UseResponseProvider: any = createContext({});

export interface ProviderProps {
  type?: PaletteMode;
  children: any;
  cookiePersistor?: any;
  req?: express.Request;
  client: ClientType;
}

export const Provider: React.FC<ProviderProps> = ({
  type,
  children,
  cookiePersistor = null,
  req = {} as express.Request,
  client
}) => {
  const isMicrosite = getIsReactMicrosite();

  if (isMicrosite) {
    return (
      <EB>
        <UseRequestProvider.Provider value={req}>
          <ApolloProvider client={client}>
            <ErrorProvider>{children}</ErrorProvider>
          </ApolloProvider>
        </UseRequestProvider.Provider>
      </EB>
    );
  }

  return (
    <EB>
      <UseRequestProvider.Provider value={req}>
        <KeycloakProviderAdapter cookiePersistor={cookiePersistor}>
          <ApolloProvider client={client}>
            <UserPreferencesProvider>
              <ErrorProvider>
                <RHNotificationProvider>
                  <ProductConfiguratorProvider>
                    <AvailableCountryProvider>
                      <SessionProvider>
                        <CountrySiteProvider>{children}</CountrySiteProvider>
                      </SessionProvider>
                    </AvailableCountryProvider>
                  </ProductConfiguratorProvider>
                </RHNotificationProvider>
              </ErrorProvider>
            </UserPreferencesProvider>
          </ApolloProvider>
        </KeycloakProviderAdapter>
      </UseRequestProvider.Provider>
    </EB>
  );
};

export default memoize(Provider);
