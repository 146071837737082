import { useIsoCookies } from "hooks/useIsoCookies";
import React, { useState, FC, createContext, useRef, useEffect } from "react";
import { countries } from "resources/countries-config.json";

import {
  COUNTRY_COOKIE,
  MEASUREMENT_COOKIE,
  LANGUAGE_COOKIE
} from "utils/constants";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { useInitialState } from "@RHCommerceDev/hooks-use-initial-state";
import { useIsPaymentPortalEstore } from "@RHCommerceDev/hooks/usePaymentPortal/useIsPaymentPortalEstore";

export type PreviousState = {
  country: string;
  language: string;
  measurement: string;
};

export interface ProviderContext {
  setCountry: (value: string) => void;
  setLanguage: (value: string) => void;
  setMeasurement: (value: string) => void;
  country: string;
  language: string;
  measurement: string;
  updatePreviousState: () => void;
  previousState: PreviousState;
  cartUpdateloading?: boolean;
  setCartUpdateLoading?: (value: boolean) => void;
  cart: CartType | null;
  setCart: React.Dispatch<React.SetStateAction<CartType | null>>;
  prevCountry: string;
  setPrevCountry: (value: string) => void;
  billingSameAsShipping?: boolean;
  setBillingSameAsShipping?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UserPreferencesContext = createContext<ProviderContext>({
  setCountry: () => {},
  setLanguage: () => {},
  setMeasurement: () => {},
  country: "US",
  language: "",
  measurement: "",
  updatePreviousState: () => {},
  previousState: {
    country: "US",
    language: "",
    measurement: ""
  },
  cartUpdateloading: false,
  setCartUpdateLoading: () => {},
  cart: null,
  setCart: () => {},
  prevCountry: "US",
  setPrevCountry: () => {},
  billingSameAsShipping: true,
  setBillingSameAsShipping: () => {}
});

export const UserPreferencesProvider: FC = ({ children }) => {
  const isoCookies = useIsoCookies([
    COUNTRY_COOKIE,
    LANGUAGE_COOKIE,
    MEASUREMENT_COOKIE
  ]);

  const defaultCountry =
    getCountryFromUrl() || isoCookies[COUNTRY_COOKIE] || "US";
  const defaultLanguage =
    isoCookies[LANGUAGE_COOKIE] ||
    getLanguageFromUrl().mapped ||
    countries?.[defaultCountry]?.defaultValues?.language;
  const defaultMeasurement =
    isoCookies[MEASUREMENT_COOKIE] ||
    countries?.[defaultCountry]?.defaultValues?.measurement;
  const [cartUpdateloading, setCartUpdateLoading] = useState(false);
  const [country, setCountry] = useState(defaultCountry);
  const [prevCountry, setPrevCountry] = useState(defaultCountry);
  const [language, setLanguage] = useState(defaultLanguage);
  const [measurement, setMeasurement] = useState(defaultMeasurement);
  const [cart, setCart] = useState<CartType | null>({});
  const { isPaymentPortalEstore } = useIsPaymentPortalEstore();
  const [billingSameAsShipping, setBillingSameAsShipping] =
    useInitialState<boolean>(isPaymentPortalEstore ? false : true);
  const [shouldUpdatePrevState, setShouldUpdatePrevState] = useState(false);
  const previousStateLocal = useRef<PreviousState>({
    country,
    language,
    measurement
  });

  const updatePreviousState = () => {
    setShouldUpdatePrevState(true);
  };

  useEffect(() => {
    if (shouldUpdatePrevState) {
      previousStateLocal.current = {
        country,
        language,
        measurement
      };
      setShouldUpdatePrevState(false);
    }
  }, [country, language, measurement, shouldUpdatePrevState]);

  return (
    <UserPreferencesContext.Provider
      value={{
        setCountry,
        setLanguage,
        setMeasurement,
        country,
        language,
        measurement,
        updatePreviousState,
        previousState: previousStateLocal.current,
        cartUpdateloading,
        setCartUpdateLoading,
        cart,
        setCart,
        prevCountry,
        setPrevCountry,
        billingSameAsShipping,
        setBillingSameAsShipping
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;
